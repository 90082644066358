#root{
  height: 100%;
}

@media screen and (max-width: 740px){
  #root{
    height: auto;
  }
}

.logo{
  position: relative;
  height: 2.437375rem;
  overflow: hidden;
}

.logo-full{
  top: 0.5rem;
  left: 0.5rem;
}

.logo-min{
  top: 0.4975rem;
}

.logo-min,
.logo-full{
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.logo-visible{
  opacity: 1;
}
