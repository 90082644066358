@media print{
  body{
    margin: 0;
    padding: 0;
    background: white !important;
  }

  body *{
    visibility: hidden;
  }

  #epg-box{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
    overflow: unset !important;
    padding-left: 0;
    padding-right: 0;
    border-color: white !important;
    background: white !important;
  }

  #epg-box h6,
  #epg-box h5{
    font-size: 10px;
  }

  .template-test{
    margin-top: 0 !important;
  }

  span[id^='icon-'] *,
  .hide-print{
    visibility: hidden !important;
    display: none;
  }

  .print-margin{
    margin-top: -4px !important;
  }

  #dates-shown *{
    color: black !important;
  }

  #draft-published *{
    visibility: hidden !important;
  }

  #channel-title{
    visibility: visible !important;
    max-width: 100% !important;
  }

  #epg-box,
  #epg-box *,
  #epg,
  #epg-header,
  #epg-header *,
  #epg *{
    visibility: visible;
  }

  div[data-value='cover']{
    overflow: unset !important;
  }

  #epg > div:nth-child(2){
    width: 1100px;
    display: block;
  }

  #epg > div:nth-child(2) > div{
    float: left;
  }

  #epg-box > div > div:first-child > div > div:not(:nth-child(4)){
    display: none;
  }

  #epg-box > div > div:first-child > div > div:nth-child(4){
    margin-bottom: 20px;
  }

  #epg > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(n){
    width: 140px;
    min-width: unset !important;
  }
}
